import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { Range } from '../catalog.interface';

import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss']
})
export class RangeComponent implements OnInit, OnDestroy {

  public range: Range;

  private langSubscription: Subscription;

  constructor(private route: ActivatedRoute, private metadata: Meta, private titleService: Title, private translate: TranslateService) {
    this.range = this.route.snapshot.data['range'];
  }

  ngOnInit(): void {
    this.setMeta();

    this.langSubscription = this.translate.onLangChange.subscribe(() => this.setMeta());
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

  public id(index: number, item: { id: string }): string {
    return item.id;
  }

  private async setMeta(): Promise<void> {
    const title = await this.translate.get('Configurator - Zodiac Nautic').toPromise();

    const description = await this.translate.get('Build your own Zodiac boat online, entirely and in 3D. You can choose your colors and materials for upholstery, hull, tube, and select your equipment, accessories and options. The online 3D configurator allows you to download your configuration in PDF.').toPromise();

    this.metadata.updateTag({ property: 'og:url', content: `${environment.host}/${this.range.id}?lang=${this.translate.currentLang}` });
    this.metadata.updateTag({ property: 'og:title', content: `${this.range.name} - ${title}` });
    this.metadata.updateTag({ property: 'og:description', content: description });
    this.metadata.updateTag({ property: 'og:type', content: 'website' });
    this.metadata.updateTag({ property: 'og:image', content: `${environment.host}/${this.range.thumbnail}?${Date.now()}` });
    this.metadata.updateTag({ property: 'og:image:width', content: '400' });
    this.metadata.updateTag({ property: 'og:image:height', content: '300' });

    this.metadata.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metadata.updateTag({ name: 'twitter:title', content: title });
    this.metadata.updateTag({ name: 'twitter:description', content: description });
    this.metadata.updateTag({ name: 'twitter:image', content: `${environment.host}/${this.range.thumbnail}?${Date.now()}` });

    this.titleService.setTitle(`${this.range.name} - ${title}`);
  }

}
