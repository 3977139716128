import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ModelComponent } from './model/model.component';
import { RangeComponent } from './range/range.component';

import { ModelResolver } from './model.resolver';
import { RangeResolver } from './range.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: ':range',
    component: RangeComponent,
    resolve: {
      range: RangeResolver
    }
  },
  {
    path: ':range/:model',
    component: ModelComponent,
    resolve: {
      range: RangeResolver,
      model: ModelResolver
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'boat'
      },
      {
        path: 'boat',
        loadChildren: () => import('./boat/boat.module').then(m => m.BoatModule)
      }, {
        path: 'equipments',
        loadChildren: () => import('./equipments/equipments.module').then(m => m.EquipmentsModule)
      }, {
        path: 'accessories',
        loadChildren: () => import('./accessories/accessories.module').then(m => m.AccessoriesModule)
      }, {
        path: 'summary',
        loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
