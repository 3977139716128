<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <ul class="navbar-nav align-items-center">
      <li class="nav-item">
        <a class="navbar-brand me-0" [routerLink]="['/']" queryParamsHandling="preserve">
          <img alt="Z-Nautic Logo" src="assets/images/logo.png" height="45" />
        </a>
      </li>
      <li class="nav-item">
        <i class="fa fas fa-chevron-right mx-3"></i>
      </li>
      <li class="nav-item">
        <span>{{range.name | translate}}</span>
      </li>
    </ul>
    <app-language-selector class="ms-auto"></app-language-selector>
  </div>
</nav>
<div class="flex-grow-1 d-flex flex-column justify-content-center">
  <div class="my-4 text-center">
    <div class="my-2 range-title" translate>THE RANGE</div>
    <h1 class="my-2 range-name">{{range.name | translate}}</h1>
  </div>
  <h1 class="m-5 fw-bolder text-center text-uppercase">{{'Choose your {range.name} to configure' | translate: {'range.name':range.name} }}</h1>
  <div class="d-flex flex-wrap align-items-center justify-content-center">
    <ng-container *ngFor="let model of range.models; trackBy: id">
      <a class="m-4" [routerLink]="[model.id]" queryParamsHandling="preserve">
        <img class="img-fluid" alt="{{model.name | translate}}" [src]="model.thumbnail" width="450" />
        <div class="m-2 text-center">{{model.name | translate}}</div>
      </a>
    </ng-container>
  </div>
</div>