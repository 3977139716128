<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <ul class="navbar-nav align-items-center">
      <li class="nav-item">
        <a class="navbar-brand me-0" [routerLink]="['/']" queryParamsHandling="preserve">
          <img alt="Z-Nautic Logo" src="assets/images/logo.png" height="45" />
        </a>
      </li>
      <li class="nav-item">
        <i class="fa fas fa-chevron-right mx-3"></i>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/', range.id]" queryParamsHandling="preserve">{{range.name | translate}}</a>
      </li>
      <li class="nav-item d-none d-md-inline-block">
        <i class="fa fas fa-chevron-right mx-3"></i>
      </li>
      <li class="nav-item d-none d-md-inline-block">
        <span>{{model.name | translate}}</span>
      </li>
    </ul>
    <app-language-selector class="ms-auto"></app-language-selector>
  </div>
</nav>
<div class="d-flex flex-wrap bg-dark border-bottom">
  <div class="d-flex col col-md-3 col-xl-3 col-xxl-2 align-items-center justify-content-center py-1 title">
    <h3>{{model.name | translate}}</h3>
  </div>
  <ul class="d-none d-md-flex col-sm-12 col-md-9 col-xl-9 col-xxl-10 nav justify-content-center">
    <ng-container *ngFor="let step of steps; index as i">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="[step.path]" routerLinkActive="active" queryParamsHandling="preserve">{{i+1}}. {{step.name | translate}}</a>
      </li>
    </ng-container>
  </ul>
  <button class="d-inline-block d-md-none col btn btn-link dropdown-toggle py-1 mx-auto" type="button" id="dropdownStepMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{currentStep?.index}}. {{currentStep?.name | translate}}
  </button>
  <div class="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="dropdownStepMenuButton">
    <ng-container *ngFor="let step of steps; index as i">
      <a class="dropdown-item" [class.d-none]="step.name === currentStep?.name" [routerLink]="step.path" queryParamsHandling="preserve">
        {{i+1}}. {{step.name | translate}}
      </a>
    </ng-container>
  </div>
</div>
<router-outlet></router-outlet>