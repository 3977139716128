import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { Model } from './catalog.interface';

import { CatalogService } from './catalog.service';

@Injectable({
  providedIn: 'root'
})
export class ModelResolver implements Resolve<Model> {
  constructor(private router: Router, private catalogService: CatalogService) { }

  resolve(route: ActivatedRouteSnapshot): Model {
    const model = this.catalogService.getModel(route.paramMap.get('range'), route.paramMap.get('model'));

    if (!model) {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }

    return model;
  }
}
