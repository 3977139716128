import { Injectable } from '@angular/core';

import { WRAPIv2 } from '@lumiscaphe/viewer';

import { nanoid } from 'nanoid';

import { AppState, BoatAccessory, BoatConfigurationParameter, BoatEquipment, BoatEquipmentInstance } from './app.state';
import { Accessory, ConfigurationParameter, ConfigurationValue, Equipment, EquipmentValue, Model, Package, Range, View } from './catalog.interface';
import { Store } from './store';

import { m4 } from './math/m4';
import { v2 } from './math/v2';
import { v3 } from './math/v3';
import Vec2 = v2.Vec2;

import { WebrenderService } from './webrender.service';

import { environment } from '../environments/environment';

@Injectable()
export class AppStoreService extends Store<AppState> {
  private storeKey = 'z-nautic.state';

  constructor(private readonly webRenderService: WebrenderService) {
    super(new AppState());

    const storeItem = sessionStorage.getItem(this.storeKey);

    if (storeItem) {
      try {
        this.state = JSON.parse(storeItem);
      } catch {
        sessionStorage.removeItem(this.storeKey);
      }
    }

    this.state$.subscribe((appState: AppState) => {
      sessionStorage.setItem(this.storeKey, JSON.stringify(appState));
    });
  }

  public selectBoat(range: Range, model: Model): void {
    this.state = {
      ...this.state,
      boat: {
        range,
        model,
        accessories: [],
        configuration: [],
        equipments: [],
        package: null
      },
      interactionHelper: true,
      view: undefined
    };

    if (model.defaultBoatConfiguration) {
      this.setBoatConfiguration(model.defaultBoatConfiguration);
    }

    const defaultPackage = model.packages.find(p => p.isDefault);

    if (defaultPackage) {
      this.toggleBoatPackage(defaultPackage);
    }
  }

  // Model

  async getModelTopViewUrl(model: Model): Promise<string> {
    const snapshot: WRAPIv2.Snapshot = {
      scene: await this.getBoatScene(false, false),
      mode: {
        image: {
          camera: model.topCamera
        }
      },
      renderParameters: {
        superSampling: '2',
        ssaoParameters: environment.webrender.parameters.ssaoParameters,
        width: 1920,
        height: 1080
      },
      encoder: {
        jpeg: {
          quality: 80
        }
      }
    };

    return (await this.webRenderService.snapshot(snapshot).toPromise() as WRAPIv2.Frame).url;
  }

  // Configuration

  public isBoatConfigurationValueSelected(parameter: ConfigurationParameter, value: ConfigurationValue): boolean {
    const boatParameter = this.state.boat.configuration.find(p => p.id === parameter.id);
    return boatParameter?.value?.id === value.id;
  }

  public selectBoatConfigurationValue(parameter: ConfigurationParameter, value: ConfigurationValue): void {
    const configuration = this.state.boat.configuration.filter(p => p.id !== parameter.id);

    const boatParameter: BoatConfigurationParameter = { ...parameter, value };

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        configuration: [
          ...configuration,
          boatParameter
        ]
      }
    };
  }

  public setBoatConfigurationParameters(parameters: BoatConfigurationParameter[]): void {
    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        configuration: parameters
      }
    };
  }

  public setBoatConfiguration(configurationString: string): void {
    const configuration = AppStoreService.configurationFromString(this.state.boat.model.parameters, configurationString);

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        configuration
      }
    };
  }

  public static configurationFromString(parameters: ConfigurationParameter[], configurationString: string): BoatConfigurationParameter[] {
    const configuration: BoatConfigurationParameter[] = [];

    const defines = configurationString.split('/');

    for (const define of defines) {
      const parameter = parameters.find(p => !!p.values.find(v => v.define === define));

      if (!parameter) {
        continue;
      }

      const value = parameter.values.find(v => v.define === define);

      if (!value) {
        continue;
      }

      const boatParameter: BoatConfigurationParameter = { ...parameter };
      boatParameter.value = { ...value };

      configuration.push(boatParameter);
    }

    return configuration;
  }

  // Accessories

  public hasAccessories(): boolean {
    return this.state.boat.model.accessories.length !== 0 || !!this.state.boat.equipments.find(e => e.value && e.value?.accessories.length !== 0);
  }

  public isAccessoryOutOfPackage(accessory: Accessory): boolean {
    return !this.state.boat.package.accessories.find(pa => pa === accessory.id);
  }

  public isBoatAccessorySelected(accessory: Accessory): boolean {
    const boatAccessory = this.state.boat.accessories.find(a => a.id === accessory.id);
    return !!boatAccessory;
  }

  public toggleBoatAccessory(accessory: Accessory): void {
    let boatAccessory = this.state.boat.accessories.find(a => a.id === accessory.id);

    if (boatAccessory) {
      const accessories = this.state.boat.accessories.filter(a => a.id !== accessory.id);

      this.state = {
        ...this.state,
        boat: {
          ...this.state.boat,
          accessories
        }
      };
    } else {
      const configuration = AppStoreService.configurationFromString(accessory.parameters, accessory.defaultConfiguration);

      boatAccessory = { ...accessory, configuration };

      this.state = {
        ...this.state,
        boat: {
          ...this.state.boat,
          accessories: [
            ...this.state.boat.accessories,
            boatAccessory
          ]
        }
      };
    }
  }

  public setBoatAccessories(accessories: BoatAccessory[]): void {
    const boatAccessories = accessories.map(accessory => {
      const configuration = AppStoreService.configurationFromString(accessory.parameters, accessory.defaultConfiguration);
      return { ...accessory, configuration };
    });

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        accessories: boatAccessories
      }
    };
  }

  public isBoatAccessoryParameterValueSelected(accessoryId: string, parameter: ConfigurationParameter, value: ConfigurationValue): boolean {
    const boatAccessory = this.state.boat.accessories.find(a => a.id === accessoryId);
    const accessoryParameter = boatAccessory.configuration.find(p => p.id === parameter.id);

    return accessoryParameter?.value?.id === value.id;
  }

  public selectBoatAccessoryParameterValue(accessoryId: string, parameter: ConfigurationParameter, value: ConfigurationValue): void {
    const accessories = this.state.boat.accessories.filter(a => a.id !== accessoryId);
    const accessory = this.state.boat.accessories.find(a => a.id === accessoryId);

    const configuration = accessory.configuration.filter(p => p.id !== parameter.id);
    const boatParameter: BoatConfigurationParameter = { ...parameter, value };

    const boatAccessory = {
      ...accessory,
      configuration: [
        ...configuration,
        boatParameter
      ]
    };

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        accessories: [
          ...accessories,
          boatAccessory
        ]
      }
    };
  }

  // Package

  public isBoatPackageSelected(pack: Package): boolean {
    return this.state.boat.package?.id === pack.id;
  }

  public toggleBoatPackage(pack: Package): void {
    if (this.isBoatPackageSelected(pack) && pack.isDefault) {
      return;
    }

    let newPack = pack;

    if (this.state.boat.package?.id === pack.id) {
      newPack = this.state.boat.model.packages.find(p => p.isDefault);
    }

    let accessories = this.state.boat.accessories;

    // Remove old package accessories not present in new package
    if (this.state.boat.package) {
      if (newPack) {
        accessories = accessories.filter(a => !this.state.boat.package.accessories.includes(a.id) || newPack.accessories.includes(a.id));
      } else {
        accessories = accessories.filter(a => !this.state.boat.package.accessories.includes(a.id));
      }
    }

    // Add new package accessories
    if (newPack) {
      const newAccessories = this.state.boat.model.accessories.filter(ma => newPack.accessories.includes(ma.id) && !accessories.find(a => ma.id === a.id) && !ma.packOption);
      accessories = [...accessories, ...newAccessories.map(a => ({ ...a, configuration: AppStoreService.configurationFromString(a.parameters, a.defaultConfiguration) }))];
    }

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        package: newPack,
        accessories
      }
    };
  }

  public isBoatPackageAccessory(accessory: Accessory): boolean {
    return !!this.state.boat.package?.accessories.find(id => id === accessory.id);
  }

  // Equipments

  public hasEquipments(): boolean {
    return this.state.boat.model.equipments.length !== 0;
  }

  public getBoatEquipment(equipmentId: string): BoatEquipment {
    return this.state.boat.equipments.find(e => e.id === equipmentId);
  }

  public getBoatEquipmentInstance(equipmentId: string, instanceId: string): BoatEquipmentInstance {
    return this.getBoatEquipment(equipmentId).instances.find(i => i.id === instanceId);
  }

  public isBoatEquipmentSelected(equipmentId: string, value: EquipmentValue): boolean {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);
    return boatEquipment?.value?.id === value.id;
  }

  public equipmentInstancesCount(equipmentId: string): number {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);
    return boatEquipment?.instances?.length;
  }

  public getEquipmentGelCoats(): ConfigurationValue[] {
    const parameters: ConfigurationParameter[] = [].concat(...this.state.boat.equipments.map(e => (e.value || e).parameters.filter(p => p.name === 'Gel Coat')));

    let values: ConfigurationValue[] = [].concat(...parameters.map(p => p.values || [p]));
    values = values.filter((e, i, a) => a.findIndex(v => v.id === e.id) === i);

    return values.sort((a, b) => a.id.localeCompare(b.id));
  }

  public async getBoatEquipmentInstanceTopImage(equipmentId: string, instanceId: string): Promise<string> {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);

    if (!boatEquipment) {
      return null;
    }

    const boatEquipmentInstance = boatEquipment.instances.find(i => i.id === instanceId);

    if (!boatEquipmentInstance) {
      return null;
    }

    const equipmentDefines = [boatEquipment.value?.define || boatEquipment.define];
    const equipmentAccessoriesDefines = boatEquipmentInstance.accessories.filter(a => !a.onBoat).map(a => a.defineOn);
    const equipmentParametersDefines = boatEquipmentInstance.configuration.map(p => p.value?.define);

    const snapshot: WRAPIv2.Snapshot = {
      scene: [
        {
          database: this.state.boat.model.database,
          visible: false,
          isMaster: true
        },
        {
          database: boatEquipment.value?.database || boatEquipment.database,
          configuration: [...equipmentDefines, ...equipmentAccessoriesDefines, ...equipmentParametersDefines].sort().join('/')
        }
      ],
      mode: {
        image: {
          camera: {
            id: this.state.boat.model.topCamera,
            sensor: {
              background: 'transparent'
            }
          }
        }
      },
      renderParameters: {
        superSampling: '2',
        ssaoParameters: environment.webrender.parameters.ssaoParameters,
        width: 1920,
        height: 1080
      },
      encoder: {
        png: {
          compression: 3
        }
      }
    };

    return (await this.webRenderService.snapshot(snapshot).toPromise() as WRAPIv2.Frame).url;
  }

  public selectBoatEquipment(equipment: Equipment, value: EquipmentValue): BoatEquipmentInstance {
    let boatEquipment = this.state.boat.equipments.find(e => e.id === equipment.id);

    let instance: BoatEquipmentInstance = { id: nanoid(), configuration: [], accessories: [] };
    instance.configuration = AppStoreService.configurationFromString(value.parameters, value.defaultConfiguration);

    if (boatEquipment) {
      boatEquipment = { ...boatEquipment, value };
      instance = boatEquipment.instances[0];
    } else {
      boatEquipment = { ...equipment, instances: [instance], value };
    }

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipment.id);

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          boatEquipment
        ]
      }
    };

    return instance;
  }

  public addBoatEquipment(equipment: Equipment): BoatEquipmentInstance {
    const instance: BoatEquipmentInstance = { id: nanoid(), configuration: [], accessories: [] };
    instance.configuration = AppStoreService.configurationFromString(equipment.parameters, equipment.defaultConfiguration);

    let boatEquipment = this.state.boat.equipments.find(e => e.id === equipment.id);

    if (boatEquipment) {
      boatEquipment = { ...boatEquipment, instances: [...boatEquipment.instances, instance] };
    } else {
      boatEquipment = { ...equipment, instances: [instance] };
    }

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipment.id);

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          boatEquipment
        ]
      }
    };

    return instance;
  }

  public removeBoatEquipmentInstance(equipmentId: string, instanceId: string): void {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);

    if (!boatEquipment) {
      return;
    }

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipmentId);

    const instances = boatEquipment.instances.filter(i => i.id !== instanceId);

    if (instances.length === 0) {
      this.removeBoatEquipment(equipmentId);
      return;
    }

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          {
            ...boatEquipment,
            instances
          }
        ]
      }
    };
  }

  public removeBoatEquipment(equipmentId: string): void {
    const equipments = this.state.boat.equipments.filter(e => e.id !== equipmentId);

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments
      }
    };
  }

  public setBoatEquipmentInstancePosition2D(equipmentId: string, instanceId: string, position2D: Vec2): void {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);
    const boatEquipmentInstance = boatEquipment.instances.find(i => i.id === instanceId);

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipmentId);
    const instances = boatEquipment.instances.filter(i => i.id !== instanceId);

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          {
            ...boatEquipment,
            instances: [
              ...instances,
              {
                ...boatEquipmentInstance,
                position2D,
                position3D: undefined,
                rotation3D: undefined
              }
            ]
          }
        ]
      }
    };
  }

  // Equipments configuration

  public isBoatEquipmentInstanceConfigurationValueSelected(equipmentId: string, instanceId: string, parameter: ConfigurationParameter, value: ConfigurationValue): boolean {
    const boatEquipmentInstance = this.getBoatEquipmentInstance(equipmentId, instanceId);
    const boatEquipmentInstanceParameter = boatEquipmentInstance.configuration.find(p => p.id === parameter.id);
    return boatEquipmentInstanceParameter?.value?.id === value.id;
  }

  public selectBoatEquipmentInstanceConfigurationValue(equipmentId: string, instanceId: string, parameter: ConfigurationParameter, value: ConfigurationValue): void {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);

    if (!boatEquipment) {
      return;
    }

    const boatEquipmentInstance = boatEquipment.instances.find(i => i.id === instanceId);

    if (!boatEquipmentInstance) {
      return;
    }

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipmentId);
    const instances = boatEquipment.instances.filter(i => i.id !== instanceId);
    const configuration = boatEquipmentInstance.configuration.filter(p => p.id !== parameter.id);
    const boatEquipmentInstanceParameter: BoatConfigurationParameter = { ...parameter, value };

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          {
            ...boatEquipment,
            instances: [
              ...instances,
              {
                ...boatEquipmentInstance,
                configuration: [
                  ...configuration,
                  boatEquipmentInstanceParameter
                ]
              }
            ]
          }
        ]
      }
    };
  }

  // Equipments accessories

  public isBoatEquipmentAccessorySelected(equipmentId: string, instanceId: string, accessory: Accessory): boolean {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);
    const boatEquipmentInstance = boatEquipment.instances.find(i => i.id === instanceId);
    return !!boatEquipmentInstance.accessories.find(a => a.id === accessory.id);
  }

  public toggleBoatEquipmentAccessory(equipmentId: string, instanceId: string, accessory: Accessory): void {
    const boatEquipment = this.state.boat.equipments.find(e => e.id === equipmentId);
    const boatEquipmentInstance = boatEquipment.instances.find(i => i.id === instanceId);

    const equipments = this.state.boat.equipments.filter(e => e.id !== equipmentId);
    const instances = boatEquipment.instances.filter(i => i.id !== instanceId);

    let accessories: BoatAccessory[];

    const instanceAccessory = boatEquipmentInstance.accessories.find(a => a.id === accessory.id);

    if (instanceAccessory) {
      accessories = boatEquipmentInstance.accessories.filter(a => a.id !== accessory.id);
    } else {
      accessories = [...boatEquipmentInstance.accessories, { ...accessory, configuration: [] }];
    }

    this.state = {
      ...this.state,
      boat: {
        ...this.state.boat,
        equipments: [
          ...equipments,
          {
            ...boatEquipment,
            instances: [
              ...instances,
              {
                ...boatEquipmentInstance,
                accessories
              }
            ]
          }
        ]
      }
    };
  }

  // Scene

  public async getBoatScene(accessories = true, equipments = true): Promise<WRAPIv2.Scene> {
    if (!this.state.boat.model.database) {
      return null;
    }

    const boatAccessoriesDefines = accessories ? this.state.boat.model.accessories.map(a => {
      let result = '';

      const accessory = this.state.boat.accessories.find(ba => ba.id === a.id);

      if (accessory) {
        result += a.defineOn;
        result += `/${accessory.configuration.map(p => p.value?.define).join('/')}`;
      } else {
        result += a.defineOff;
      }

      return result;
    }
    ) : [];

    const boatDefines = this.state.boat.configuration.map(p => p.value?.define);

    const boat: WRAPIv2.Product = {
      database: this.state.boat.model.database,
      configuration: [...boatAccessoriesDefines, ...boatDefines].sort().join('/'),
      isMaster: true
    };

    if (!equipments) {
      return boat;
    }

    if (this.state.boat.equipments.length) {
      await this.updateEquipmentInstancesPosition3D();
    }

    const boatEquipments: WRAPIv2.Product[] = [];

    for (const equipment of this.state.boat.equipments) {
      for (const instance of equipment.instances) {
        const equipmentDefines = [equipment.value?.define || equipment.define];
        const equipmentAccessoriesDefines = instance.accessories.map(a => a.defineOn);
        const equipmentParametersDefines = instance.configuration.map(p => p.value?.define);

        if (!instance?.position3D || (!equipment.value?.database && !equipment.database)) {
          continue;
        }

        boatEquipments.push({
          database: equipment.value?.database || equipment.database,
          configuration: [...equipmentDefines, ...equipmentAccessoriesDefines, ...equipmentParametersDefines].sort().join('/'),
          translation: { x: instance.position3D[0], y: instance.position3D[1], z: instance.position3D[2] },
          rotation: { x: instance.rotation3D[0], y: instance.rotation3D[1], z: instance.rotation3D[2] }
        });
      }
    }

    return [boat, ...boatEquipments];
  }

  public async updateEquipmentInstancesPosition3D(): Promise<void> {
    const pick: WRAPIv2.Pick = {
      scene: await this.getBoatScene(false, false),
      camera: this.state.boat.model.topCamera,
      renderParameters: { width: 1920, height: 1080 },
      positions: []
    };

    const pickData: { equipment: BoatEquipment; instance: BoatEquipmentInstance }[] = [];

    for (const equipment of this.state.boat.equipments) {
      for (const instance of equipment.instances) {
        if (instance.position3D && instance.rotation3D) {
          continue;
        }

        const cropArea = equipment.value?.cropArea || equipment.cropArea;

        const tl = [instance.position2D[0] - cropArea.w / 2, instance.position2D[1] - cropArea.h / 2];
        const tr = [instance.position2D[0] + cropArea.w / 2, instance.position2D[1] - cropArea.h / 2];
        const bl = [instance.position2D[0] - cropArea.w / 2, instance.position2D[1] + cropArea.h / 2];
        const br = [instance.position2D[0] + cropArea.w / 2, instance.position2D[1] + cropArea.h / 2];

        const positions = [instance.position2D, tl, tr, bl, br].map(p => ({ x: p[0], y: p[1] }));

        pick.positions = [...pick.positions, ...positions];

        pickData.push({ equipment, instance });
      }
    }

    if (pick.positions.length === 0) {
      return;
    }

    const response = await this.webRenderService.pick(pick).toPromise();

    pickData.forEach((data, index) => {
      const pickResults = response.slice(index * 5, (index + 1) * 5);

      const position3D = Object.values(pickResults[0].point);
      position3D[0] -= data.equipment.value?.offsetX || data.equipment.offsetX;
      position3D[2] -= data.equipment.value?.offsetY || data.equipment.offsetY;

      const yAxis = [0, 1, 0];

      const zRotations = pickResults.filter(pickResult => !!pickResult).map(pickResult => {
        const normal = Object.values(pickResult.normal);
        const normalAxisDiff = v3.abs(v3.subtract(normal, yAxis));

        if (normalAxisDiff[0] < 0.001 && normalAxisDiff[1] < 0.001 && normalAxisDiff[2] < 0.001)
          return (0).toFixed(1);

        const rotation3D = m4.eulerRotation(m4.createRotationMatrixFromVectors(normal, yAxis));
        return rotation3D[2].toFixed(1);
      });

      const zRotation = zRotations.sort((a, b) => zRotations.filter(v => v === a).length - zRotations.filter(v => v === b).length).pop();

      data.instance.error = false;
      data.instance.position3D = position3D;
      data.instance.rotation3D = [0, 0, parseFloat(zRotation)];
    });
  }

  // Viewer
  public setInteractionHelper(interactionHelper: boolean): void {
    this.state = {
      ...this.state,
      interactionHelper
    };
  }

  public setView(view: View): void {
    this.state = {
      ...this.state,
      view
    };
  }
}
