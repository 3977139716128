import { Accessory, ConfigurationParameter, ConfigurationValue, Equipment, EquipmentValue, Model, Range, View, Package } from './catalog.interface';
import { v3 } from './math/v3';
import Vec3 = v3.Vec3;
import { v2 } from './math/v2';
import Vec2 = v2.Vec2;

export class AppState {
  boat: Boat;
  interactionHelper: boolean;
  view?: View;
}

export interface Boat {
  range: Range;
  model: Model;

  accessories: BoatAccessory[];
  configuration: BoatConfigurationParameter[];
  equipments: BoatEquipment[];
  package: Package;
}

export interface BoatAccessory extends Accessory {
  configuration: BoatConfigurationParameter[];
}

export interface BoatConfigurationParameter extends ConfigurationParameter {
  value?: ConfigurationValue;
}

export interface BoatEquipment extends Equipment {
  instances: BoatEquipmentInstance[];
  value?: EquipmentValue;
}

export interface BoatEquipmentInstance {
  id: string;
  error?: boolean;
  position2D?: Vec2;
  position3D?: Vec3;
  rotation3D?: Vec3;
  accessories: BoatAccessory[];
  configuration: BoatConfigurationParameter[];
}
