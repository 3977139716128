import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { RangeComponent } from './range/range.component';
import { ModelComponent } from './model/model.component';

import { AppRoutingModule } from './app-routing.module';

import { AppStoreService } from './app.store.service';
import { CatalogService } from './catalog.service';
import { PForgeService } from './pforge.service';

import { TranslatePoHttpLoader } from './TranslatePoHttpLoader';

import { environment } from '../environments/environment'

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LanguageSelectorComponent,
    ModelComponent,
    RangeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    AppStoreService,
    CatalogService,
    PForgeService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
