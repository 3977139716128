import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Catalog } from '../catalog.interface';

import { environment } from 'src/environments/environment';

import catalog from 'src/assets/catalog.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public ranges: Catalog;
  public rangeDescription: boolean;

  private langSubscription: Subscription;

  constructor(private metadata: Meta, private translate: TranslateService, private titleService: Title) {
    this.ranges = catalog as Catalog;
  }

  ngOnInit(): void {
    this.setMeta();

    this.langSubscription = this.translate.onLangChange.subscribe(() => this.setMeta());
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

  public id(index: number, item: { id: string }): string {
    return item.id;
  }

  private async setMeta(): Promise<void> {
    this.rangeDescription = await this.translate.get('Ranges description').toPromise() !== 'Ranges description';

    const title = await this.translate.get('Configurator - Zodiac Nautic').toPromise();

    const description = await this.translate.get('Build your own Zodiac boat online, entirely and in 3D. You can choose your colors and materials for upholstery, hull, tube, and select your equipment, accessories and options. The online 3D configurator allows you to download your configuration in PDF.').toPromise();

    this.metadata.updateTag({ property: 'og:url', content: `${environment.host}/?lang=${this.translate.currentLang}` });
    this.metadata.updateTag({ property: 'og:title', content: title });
    this.metadata.updateTag({ property: 'og:description', content: description });
    this.metadata.updateTag({ property: 'og:type', content: 'website' });
    this.metadata.updateTag({ property: 'og:image', content: `${environment.host}/assets/images/banner.jpg` });
    this.metadata.updateTag({ property: 'og:image:width', content: '400' });
    this.metadata.updateTag({ property: 'og:image:height', content: '300' });

    this.metadata.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metadata.updateTag({ name: 'twitter:title', content: title });
    this.metadata.updateTag({ name: 'twitter:description', content: description });
    this.metadata.updateTag({ name: 'twitter:image', content: `${environment.host}/assets/images/banner.jpg` });

    this.titleService.setTitle(title);
  }
}
