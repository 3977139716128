<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <ul class="navbar-nav align-items-center">
      <li class="nav-item">
        <a class="navbar-brand me-0" [routerLink]="['/']" queryParamsHandling="preserve">
          <img alt="Z-Nautic Logo" src="assets/images/logo.png" height="45" />
        </a>
      </li>
    </ul>
    <app-language-selector class="ms-auto"></app-language-selector>
  </div>
</nav>
<div class="flex-grow-1 d-flex flex-column justify-content-center">
  <img class="img-fluid" alt="banner" src="assets/images/banner.jpg" />
  <h1 class="mt-0 mb-5 mx-5 fw-bolder text-center text-uppercase" translate>Choose the range you want to configure</h1>
  <h3 class="m-3 fw-bolder text-center" translate *ngIf="rangeDescription">Ranges description</h3>
  <div class="d-flex flex-wrap align-items-center justify-content-center">
    <ng-container *ngFor="let range of ranges; trackBy: id">
      <a class="m-4" [routerLink]="[range.id]" queryParamsHandling="preserve">
        <img alt="{{range.name | translate}}" class="img-fluid" [src]="range.thumbnail" width="450" />
        <div class="m-2 text-center">{{range.name | translate}}</div>
      </a>
    </ng-container>
  </div>
</div>