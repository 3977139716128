import { EventEmitter, Injectable } from '@angular/core';

import countries from '../assets/i18n/countries.json';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  countries: { code: string; name: string }[] = [];

  onCountriesUpdate: EventEmitter<{ code: string; name: string }[]> = new EventEmitter<{ code: string; name: string }[]>();

  retrieveCountries(lang: string): void {
    this.countries = countries.map(c => ({ code: c.alpha3, name: c[lang] || c['en'] }));

    this.countries.sort((a, b) => a.name.localeCompare(b.name));

    this.onCountriesUpdate.emit(this.countries);
  }
}
