import { v3 } from './v3';

export namespace m4 {
  import Vec3 = v3.Vec3;
  export type Mat4 = Array<number[]> | Array<Float32Array>;

  export const createRotationMatrixFromVectors = (s: Vec3, t: Vec3): Mat4 => {
    const v: Vec3 = v3.cross(s, t);
    const e: number = v3.dot(s, t);
    const h: number = (1 - e) / v3.dot(v, v);
    const vx: number = v[0];
    const vy: number = v[1];
    const vz: number = v[2];

    return [
      [e + h * vx * vx, h * vx * vy - vz, h * vx * vz + vy, 0],
      [h * vx * vy + vz, e + h * vy * vy, h * vy * vz - vx, 0],
      [h * vx * vz - vy, h * vy * vz + vx, e + h * vz * vz, 0],
      [0, 0, 0, 1]
    ];
  };

  export const eulerRotation = (m: Mat4): Vec3 => {
    const pi2: number = Math.PI / 2;
    let x = 0;
    let y = 0;
    let z = 0;

    if ((m[1][2] === 0) && (m[2][2] === 0)) {
      x = 0;
      y = -m[0][2] * pi2;
      z = Math.atan2(-m[1][0], m[1][1]);
    }
    else {
      x = Math.atan2(m[1][2], m[2][2]);
      z = Math.atan2(m[0][1], m[0][0]);

      if (Math.abs(x) >= pi2) {
        x = Math.atan2(-m[1][2], -m[2][2]);
        z = Math.atan2(-m[0][1], -m[0][0]);
      }

      const cx = Math.cos(x);
      const sx = Math.sin(x);
      const cy = (Math.abs(cx) > Math.abs(sx)) ? (m[2][2] / cx) : (m[1][2] / sx);
      y = Math.atan2(-m[0][2], cy);
    }

    const degreeAngleFactor = 180 / Math.PI;

    return [x * degreeAngleFactor, y * degreeAngleFactor, z * degreeAngleFactor];
  };
}
