import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CountriesService } from '../countries.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  private exceptions = {
    en: 'gb'
  };

  public languages: string[] = [];
  public currentLanguage: string;

  constructor(private router: Router, private translateService: TranslateService, private countriesService: CountriesService) {
    this.init();

    translateService.onLangChange.subscribe(this.init.bind(this));
  }

  public init(): void {
    this.currentLanguage = this.exceptions[this.translateService.currentLang] || this.translateService.currentLang;
    this.languages = this.translateService.langs.filter(l => l !== this.getExceptionKey(this.currentLanguage)).map(l => this.exceptions[l] || l);
    this.countriesService.retrieveCountries(this.translateService.currentLang);
  }

  public selectLanguage(language: string): void {
    this.translateService.use(this.getExceptionKey(language));
    this.router.navigate([], { queryParams: { lang: language }, queryParamsHandling: 'merge' });
  }

  private getExceptionKey(value: string): string {
    for (const key in this.exceptions) {
      if (this.exceptions[key] === value) {
        return key;
      }
    }

    return value;
  }
}
