export namespace v3 {
  export type Vec3 = number[] | Float32Array;

  export const abs = (u: Vec3): Vec3 => [Math.abs(u[0]), Math.abs(u[1]), Math.abs(u[2])];

  export const cross = (u: Vec3, v: Vec3): Vec3 => [u[1] * v[2] - u[2] * v[1], u[2] * v[0] - u[0] * v[2], u[0] * v[1] - u[1] * v[0]];

  export const dot = (u: Vec3, v: Vec3): number => u[0] * v[0] + u[1] * v[1] + u[2] * v[2];

  export const subtract = (u: Vec3, v: Vec3): Vec3 => [u[0] - v[0], u[1] - v[1], u[2] - v[2]];
}
