export const environment = {
  production: true,
  host: 'https://configure.zodiac-nautic.com',
  api: 'https://zodiac-api-crm.herokuapp.com',
  webrender: {
    server: 'https://wr-zodiac.lumiscaphe.com',
    parameters: {
      superSampling: '2',
      ssaoParameters: {
        enabled: true,
        lightmaps: true,
        transparentSurfaces: false,
        radius: 0.05,
        intensity: 1,
        bias: 8,
        steps: 4
      }
    },
    encoder: {
      format: 'jpeg',
      quality: 80
    }
  },
  pforge: {
    server: 'https://pforge.lumiscaphe.com',
    target: 'zodiac'
  }
};
