import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('window:resize') onResize(): void {
    this.updateVHProperty();
  }

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(['fr', 'de', 'en', 'es', 'it']);

    this.translateService.setDefaultLang('fr');

    const urlParams = new URLSearchParams(location.search);
    const browserLanguage = urlParams.get('lang') || this.translateService.getBrowserLang();
    this.translateService.use(browserLanguage.match(/de|en|es|fr|it/) ? browserLanguage : this.translateService.defaultLang);
  }

  ngOnInit(): void {
    this.updateVHProperty();
  }

  updateVHProperty(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
