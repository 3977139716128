import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Boat } from '../app.state';
import { Model, Range } from '../catalog.interface';

import { AppStoreService } from '../app.store.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit, OnDestroy {
  public range: Range;
  public model: Model;
  public boat: Boat;
  public steps: { name: string; path: string }[] = [];

  private langSubscription: Subscription;
  private subscription: Subscription;

  get currentStep(): { name: string; path: string; index: number } {
    const index = this.steps.findIndex(s => location.pathname.indexOf(s.path.replace('.', '')) >= 0);

    return { ...this.steps[index], index: index + 1 };
  }

  public constructor(private route: ActivatedRoute, private appStoreService: AppStoreService, private metadata: Meta, private titleService: Title, private translate: TranslateService,) {
    this.range = this.route.snapshot.data['range'];
    this.model = this.route.snapshot.data['model'];
  }

  ngOnInit(): void {
    this.setMeta();

    this.langSubscription = this.translate.onLangChange.subscribe(() => this.setMeta());

    this.subscription = this.appStoreService.state$.subscribe((state) => {
      this.boat = state.boat;

      if (this.boat) {
        this.initSteps();
      }
    });

    if (!this.boat || this.boat.range.id !== this.range.id || this.boat.model.id !== this.model.id) {
      this.appStoreService.selectBoat(this.range, this.model);
    }
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  private async setMeta(): Promise<void> {
    const title = await this.translate.get('Configurator - Zodiac Nautic').toPromise();

    const description = await this.translate.get('Build your own Zodiac boat online, entirely and in 3D. You can choose your colors and materials for upholstery, hull, tube, and select your equipment, accessories and options. The online 3D configurator allows you to download your configuration in PDF.').toPromise();

    this.metadata.updateTag({ property: 'og:url', content: `${environment.host}/${this.range.id}/${this.model.id}?lang=${this.translate.currentLang}` });
    this.metadata.updateTag({ property: 'og:title', content: `${this.model.name} - ${title}` });
    this.metadata.updateTag({ property: 'og:description', content: description });
    this.metadata.updateTag({ property: 'og:type', content: 'website' });
    this.metadata.updateTag({ property: 'og:image', content: `${environment.host}/${this.model.thumbnail}?${Date.now()}` });
    this.metadata.updateTag({ property: 'og:image:width', content: '400' });
    this.metadata.updateTag({ property: 'og:image:height', content: '300' });

    this.metadata.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metadata.updateTag({ name: 'twitter:title', content: title });
    this.metadata.updateTag({ name: 'twitter:description', content: description });
    this.metadata.updateTag({ name: 'twitter:image', content: `${environment.host}/${this.model.thumbnail}?${Date.now()}` });

    this.titleService.setTitle(`${this.model.name} - ${title}`);
  }

  private initSteps(): void {
    this.steps = [];

    this.steps.push({ name: 'Boat', path: './boat' });

    if (this.appStoreService.hasEquipments()) {
      this.steps.push({ name: 'Equipments', path: './equipments' });
    }

    if (this.appStoreService.hasAccessories()) {
      this.steps.push({ name: 'Accessories', path: './accessories' });
    }

    this.steps.push({ name: 'Summary', path: './summary' });
  }
}
