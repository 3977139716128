import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PforgeAssignments, PforgeConfigurationResponse, PforgeSolutionsResponse } from './pforge.interface';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PForgeService {

  constructor(private readonly http: HttpClient) { }

  public configuration(filename: string, assignments: PforgeAssignments): Observable<PforgeConfigurationResponse> {
    return this.http.post<PforgeConfigurationResponse>(`${environment.pforge.server}/configuration/${environment.pforge.target}/${filename}`, assignments, { responseType: 'json' });
  }

  public constraints(filename: string, variableIds: string[]): Observable<string[]> {
    return this.http.post<string[]>(`${environment.pforge.server}/constraints/${environment.pforge.target}/${filename}`, variableIds, { responseType: 'json' });
  }

  public solutions(filename: string, assignments: PforgeAssignments): Observable<PforgeSolutionsResponse> {
    return this.http.post<PforgeSolutionsResponse>(`${environment.pforge.server}/solutions/${environment.pforge.target}/${filename}`, assignments, { responseType: 'json' });
  }
}
