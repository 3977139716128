import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { Range } from './catalog.interface';

import { CatalogService } from './catalog.service';

@Injectable({
  providedIn: 'root'
})
export class RangeResolver implements Resolve<Range> {
  constructor(private router: Router, private catalogService: CatalogService) { }

  resolve(route: ActivatedRouteSnapshot): Range {
    const range = this.catalogService.getRange(route.paramMap.get('range'));

    if (!range) {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }

    return range;
  }
}
