import { Injectable } from '@angular/core';

import { Catalog, Model, Range } from './catalog.interface';

import catalog from '../assets/catalog.json';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  getRange(id: string): Range | undefined {
    return (catalog as Catalog).find(range => range.id === id);
  }

  getModel(rangeId: string, modelId: string): Model | undefined {
    const range = this.getRange(rangeId);

    return range?.models.find(model => model.id === modelId);
  }

}
